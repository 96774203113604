import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import detailsList from "./details-list";

export default function Details() {
  const { id } = useParams();



  const selectedCard = detailsList.find((card) => card.id === parseInt(id));

  return (
    <>
      <Header />
      <section className={`detail_page ${selectedCard.class}`}>
        <div className="sub-section">
          <div className="container">
            <h2 className="sub-section-h1 text-start">{selectedCard.title}</h2>
          </div>
        </div>
      </section>
      <section className="benefits-sub-tab">
        <div className="container">
          <h2 className="sub-section-h2">Special Benefits Of Us</h2>
          <div className="row sub-tab-4">
            <div className="col-lg-3">
              <div className="sub-tab-card">
                <p className="sub-card-p margin-zero">{selectedCard.benefit1}</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub-tab-card">
                <p className="sub-card-p margin-zero">{selectedCard.benefit2}</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub-tab-card">
                <p className="sub-card-p margin-zero">{selectedCard.benefit3}</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub-tab-card">
                <p className="sub-card-p margin-zero">{selectedCard.benefit4}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="screenshot-demo section-padding">
  <div className="container">
    <div className="screenshot-demo-content">
      <h2 className="sub-section-h2">Screenshot Segment of {selectedCard.title}</h2>
      <p className="section-desc margin-zero">{selectedCard.segment}</p>
    </div>
    <div className="screenshot-row">
    {selectedCard.screenshots.map((screenshot, index) => (
              <div
                className={`row screenshot-container ${
                  index % 2 === 0 ? "container-even" : "container-odd"
                } margin-10-per`}
                key={screenshot["screen-shot-id"]}
              >
                {index % 2 === 0 ? (
                  <>
                    <div className="col-lg-7 screenshot-left">
                      <img src={screenshot.imgSrc} className="bitcoin" alt={screenshot.altText} />
                    </div>
                    <div className="col-lg-5 screenshot-right flex-col justify-center">
                      <p className="screenshot-title">{screenshot.title}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-5 screenshot-right flex-col justify-center">
                      <p className="screenshot-title">{screenshot.title}</p>
                    </div>
                    <div className="col-lg-7 screenshot-left">
                      <img src={screenshot.imgSrc} className="bitcoin" alt={screenshot.altText} />
                    </div>
                  </>
                )}
              </div>
            ))}
    </div>
  </div>
</section>

<Footer />
    </>
  );
}
