const Video = [
    {
      id: 1,
      title: "Graphics",
      description: 'Description for Screenshot 1',
      imgSrc: 'https://www.youtube.com/embed/BIhWoFqehHM',
      altText: 'Screenshot 1',
    },
    {
      id: 2,
      title: 'Graphics',
      description: 'Description for Screenshot 2',
      imgSrc: 'https://www.youtube.com/embed/IAceFEXkz_w',
      altText: 'Screenshot 2',
    },
  ];
  
  export default Video;
