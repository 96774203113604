import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DetailsPage from "./DetailsPage.js";
import "./Style.css";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className="sub-category" id="session2">
        <div className="hero-bg">
          <div className="container">
            <h2 className="clienthead my-3">Our Clients</h2>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Baalcoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Baal Coin
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/BitxInd0.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    BitxInd
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/BlazarToken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Blazar Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Comodotoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Comodo Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/DBP.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    DBP Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Discountsales.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Discount Sale
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Edufex.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Edufex Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Everrisetoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Everrise Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/HTCtoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    HTC Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Immortal.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Immortal Nodes
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Junglerace.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Jungle Race
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/MACtoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MAC Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/MARScoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MARS Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Memeunity.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Memeunity{" "}
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Passivetoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Passive Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Pawtocol.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Pawtocol
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Retiretoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Retire Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Shikagetoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Shikage Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Shinobiverse.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Shinobi verse
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Speedtoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Speed Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Squidworld.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Squidworld
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Sricoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Sri Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Steamfinance.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Steam Finance
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Tanzanite.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tanzanite Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/ThisismeToken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    This is me Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/TradeX.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    TradeX Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Ubuntuswap.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Ubuntu Swap
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Wen Lambo Token.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Wen Lambo Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Yarloo.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Yarloo
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/ample.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">Ample</h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/babymatic.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Baby Matic
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/bitvix.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Bitvix
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/blackmamb.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Black Mamba
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/bnbroom.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    BNB Room DEFI
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/bsgfod.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    BSG Fod
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/cbai.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    CBAI token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/cholalotto.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Cholalotto
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/chrome.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Chrome Swap
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/daifarm.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Dai Farm
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/entireswap.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    EntireSwap
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/fortuner.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Fortunner
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/grizzly.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Grizzly Stake
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/hyperavax.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Hyper Avax
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/inherit.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Inherit Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/inrd.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">INRD</h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/keedx.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">KeeDx</h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/kojucoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Koju Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/marvininu.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Marvin Inu
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/mazatoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Maze Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/metaacres.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Meta Acres
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/mgrtoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MGR Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/minisportzilla.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Mini Sport Zilla
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/mxefinance.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MXE Finance
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/myda.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Myda Dex
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/myhousecoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    My House Coin
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/mystic.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Mystic Warriors
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  {" "}
                  <img
                    src={require("../Images/client/piggymoney.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Piggy Money
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/primetoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Prime Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/printrcoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Printr Coin
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  {" "}
                  <img
                    src={require("../Images/client/saitonbi.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Saitonbi
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/speedswap.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Speed Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/t2xtoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    T2X Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/terrnglobal.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Terren Global
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tron369.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron369
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronair.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron Air
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronhero.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron Hero
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronspark.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron Spark
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronworls.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron World
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronxp.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron XP
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronzen.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tronzen
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  {" "}
                  <img
                    src={require("../Images/client/trxchain.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    TRX Chain
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  {" "}
                  <img
                    src={require("../Images/client/unitron.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Uni Tron
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/xswap.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">XSwap</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/coinx.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">CoinX</h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  {" "}
                  <img
                    src={require("../Images/client/yolocabs.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Yolo Cabs
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Spenderstoken.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Spenders Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Dtt.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    DTT Exchange
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Cryptounity.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Cryptounity
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/digitaldinar.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Digital Dinar
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/doodle.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Doodle token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/exchange99.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Exchange99
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/infinitypad.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    InfinityPad
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/jabal.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">Jabal</h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/lppcoin.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    LPP Coin
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/maalscan.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MaalScan Blockchain
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/misato.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Misato Token
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/mmmdefi.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    MMM DEFI
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/poolfinance.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    1 Pool Finance
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/sarvafarm.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Sarva Farm
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/solalgo.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Solalgo
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/teambalze.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Team Blaze
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronalexa.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron Alexa
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronwhirl.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron Whirl Wind
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/tronx.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Tron X
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/Wireswap.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Wire Swap
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/advocatealok.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Advocate Alok{" "}
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/amd.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    AMD Token
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/coinpad.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    CoinxPad
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/blockpad.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Blockpad
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/cryptobanks.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    Crypto Banks
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../Images/client/dexverry.webp")}
                    className="my-3 d-block mx-auto"
                  />
                  <h5 className="text-white text-center mt-auto mb-3">
                    DexBerry
                  </h5>
                </div>
              </div>{" "}
              <div className="col-lg-2 col-md-3 col-sm-5 mx-3 my-4  clientscards">
                <div className="d-flex flex-column align-items-center">
                  <h5 className="text-white text-center mt-auto mb-3">
                    And many more.
                  </h5>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
