import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "./Style.css";

export default function Faq() {
  return (
    <>
       <Header />
       <section className="hero">
        <div className="hero-bg">
          <div className="container">
            <div className="hero-content">
              <h1 >BLOCK INTELLIGENCE</h1>
              <p className='animate-gradient' >The Tomorrow’s World Is Available Here Today</p>
              <button>Explore Now</button>
            </div>
          </div>
        </div>
      </section>
       <Footer />
    </>
  )
}
