const cardDetails = [
  ///////////////// ICO ////////////////
  {
    id: 1,
    title: "ICO",
    class: "ico_bg",
    benefit1: "Increased Liquidity", benefit2: "Global Reach", benefit3: "Tokenization", benefit4: "Security",
    content: "Content for ICO", 
    segment:"Dive into the world of initial coin offerings (ICOs) through our captivating screenshot showcase. Explore snapshots of successful token sales, innovative blockchain projects, and the transformative potential of ICOs. Immerse yourself in the exciting realm of cryptocurrency and witness the power of ICOs to revolutionize industries and drive digital innovation",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "1Poolfinance",
        description: "Description for 1poolfinance",
        imgSrc:  require('../Images/ICO/1poolfinance.webp'),
        altText: "1poolfinance",
      },
      {
        "screen-shot-id": 2,
        title: "Bitvix",
        description: "Description for Bitvix",
        imgSrc:  require('../Images/ICO/Bitvix.webp'),
        altText: "Bitvix",
      },
      {
        "screen-shot-id": 3,
        title: "Cbaitoken",
        description: "Description for cbaitoken",
        imgSrc:  require('../Images/ICO/cbaitoken.webp'),
        altText: "Cbaitoken",
      },
      {
        "screen-shot-id": 1,
        title: "Coinx",
        description: "Description for coinx",
        imgSrc:  require('../Images/ICO/coinx.webp'),
        altText: "Coinx",
      },
      {
        "screen-shot-id": 4,
        title: "Coinx1",
        description: "Description for coinx1",
        imgSrc:  require('../Images/ICO/coinx1.webp'),
        altText: "Coinx1",
      },
      {
        "screen-shot-id": 5,
        title: "Dexberry",
        description: "Description for dexberry",
        imgSrc:  require('../Images/ICO/dexberry.webp'),
        altText: "Dexberry",
      },
      {
        "screen-shot-id": 6,
        title: "Entireswap",
        description: "Description for entireswap",
        imgSrc:  require('../Images/ICO/entireswap.webp'),
        altText: "Entireswap",
      },
      {
        "screen-shot-id": 7,
        title: "Exchange99",
        description: "Description for exchange99",
        imgSrc:  require('../Images/ICO/exchange99.webp'),
        altText: "Exchange99",
      },
      {
        "screen-shot-id": 8,
        title: "Kojucoin",
        description: "Description for kojucoin",
        imgSrc:  require('../Images/ICO/kojucoin.webp'),
        altText: "Kojucoin",
      },
      {
        "screen-shot-id": 9,
        title: "Metaacres",
        description: "Description for metaacres",
        imgSrc:  require('../Images/ICO/metaacres.webp'),
        altText: "Metaacres",
      },
      {
        "screen-shot-id": 10,
        title: "Myhomecoin",
        description: "Description for myhomecoin",
        imgSrc:  require('../Images/ICO/myhomecoin.webp'),
        altText: "myhomecoin",
      },
      {
        "screen-shot-id": 11,
        title: "Shillitbaby",
        description: "Description for shillitbaby",
        imgSrc:  require('../Images/ICO/shillitbaby.webp'),
        altText: "shillitbaby",
      },
      {
        "screen-shot-id": 12,
        title: "Team Blaze Token",
        description: "Description for shillitbaby",
        imgSrc:  require('../Images/ICO/tbttoken.webp'),
        altText: "Team Blaze Token",
      },
      
    ],
  },
  ///////////////// MLM ////////////////
  {
    id: 2,
    title: "MLM",
    class: "mlm_bg",
    benefit1: "Decentralized", benefit2: "Smart Contract", benefit3: "Secure", benefit4: "P2P Transaction",
    content: "Content for MLM",
    segment:"Take a glimpse into the dynamic world of multi-level marketing (MLM) through our captivating screenshot showcase. Immerse yourself in the exciting realm of MLM and explore the possibilities of building a thriving business network",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Reefchain",
        description: "reefchain",
        imgSrc:  require('../Images/MLM/reefchain.webp'),
        altText: "reefchain",
      },
       {
        "screen-shot-id": 2,
        title: "Reefchain1",
        description: "reefchain1",
        imgSrc:  require('../Images/MLM/reefchain1.webp'),
        altText: "reefchain1",
      },
      {
        "screen-shot-id": 3,
        title: "Tron360",
        description: "tron360",
        imgSrc:  require('../Images/MLM/tron360.webp'),
        altText: "tron360",
      },
      {
        "screen-shot-id": 4,
        title: "Tron360-1",
        description: "tron3601",
        imgSrc:  require('../Images/MLM/tron3601.webp'),
        altText: "tron3601",
      },
      {
        "screen-shot-id": 5,
        title: "Tron360-2",
        description: "tron3602",
        imgSrc:  require('../Images/MLM/tron3602.webp'),
        altText: "tron3602",
      },
      {
        "screen-shot-id": 6,
        title: "Tronalexa",
        description: "tronalexa",
        imgSrc:  require('../Images/MLM/tronalexa.webp'),
        altText: "tronalexa",
      },
      {
        "screen-shot-id": 7,
        title: "Tronalexa-1",
        description: "tronalexa1",
        imgSrc:  require('../Images/MLM/tronalexa1.webp'),
        altText: "tronalexa1",
      },
      {
        "screen-shot-id": 8,
        title: "Tronalexa-2",
        description: "tronalexa2",
        imgSrc:  require('../Images/MLM/tronalexa2.webp'),
        altText: "tronalexa2",
      },
      {
        "screen-shot-id": 9,
        title: "Tronchainer",
        description: "tronchainer",
        imgSrc:  require('../Images/MLM/tronchainer.webp'),
        altText: "tronchainer",
      },
      {
        "screen-shot-id": 10,
        title: "Tronchainer-1",
        description: "tronchainer1",
        imgSrc:  require('../Images/MLM/tronchainer1.webp'),
        altText: "tronchainer1",
      },
      {
        "screen-shot-id": 11,
        title: "Tronchainer-2",
        description: "tronchainer2",
        imgSrc:  require('../Images/MLM/tronchainer2.webp'),
        altText: "tronchainer2",
      },
      {
        "screen-shot-id": 12,
        title: "Tronhero",
        description: "tronhero",
        imgSrc:  require('../Images/MLM/tronhero.webp'),
        altText: "tronhero",
      },
      {
        "screen-shot-id": 13,
        title: "Tronhero1",
        description: "tronhero1",
        imgSrc:  require('../Images/MLM/tronhero1.webp'),
        altText: "tronhero1",
      },
      {
        "screen-shot-id": 14,
        title: "Tronspark",
        description: "tronspark",
        imgSrc:  require('../Images/MLM/tronspark.webp'),
        altText: "tronspark",
      },
      {
        "screen-shot-id": 15,
        title: "Tronspark-1",
        description: "tronspark1",
        imgSrc:  require('../Images/MLM/tronspark1.webp'),
        altText: "tronspark1",
      },
      {
        "screen-shot-id": 16,
        title: "Tronwhirlwind",
        description: "tronwhirlwind",
        imgSrc:  require('../Images/MLM/tronwhirlwind.webp'),
        altText: "tronwhirlwind",
      },
      {
        "screen-shot-id": 17,
        title: "Tronwhirlwind1",
        description: "tronwhirlwind1",
        imgSrc:  require('../Images/MLM/tronwhirlwind1.webp'),
        altText: "tronwhirlwind1",
      },
      {
        "screen-shot-id": 18,
        title: "Tronwhirlwind-2",
        description: "tronwhirlwind2",
        imgSrc:  require('../Images/MLM/tronwhirlwind2.webp'),
        altText: "tronwhirlwind2",
      },
      {
        "screen-shot-id": 19,
        title: "Tronworld",
        description: "tronworld",
        imgSrc:  require('../Images/MLM/tronworld.webp'),
        altText: "tronworld",
      },
      {
        "screen-shot-id": 20,
        title: "Tronworld-1",
        description: "tronworld1",
        imgSrc:  require('../Images/MLM/tronworld1.webp'),
        altText: "tronworld1",
      },
      {
        "screen-shot-id": 21,
        title: "Tronxp",
        description: "tronxp",
        imgSrc:  require('../Images/MLM/tronxp.webp'),
        altText: "tronxp",
      },
      {
        "screen-shot-id": 22,
        title: "Tronxp-2",
        description: "tronxp2",
        imgSrc:  require('../Images/MLM/tronxp2.webp'),
        altText: "tronxp2",
      },
      {
        "screen-shot-id": 23,
        title: "Tronxp-3",
        description: "tronxp3",
        imgSrc:  require('../Images/MLM/tronxp3.webp'),
        altText: "tronxp3",
      },
      {
        "screen-shot-id": 24,
        title: "Tronzen",
        description: "tronzen",
        imgSrc:  require('../Images/MLM/tronzen.webp'),
        altText: "tronzen",
      },
      {
        "screen-shot-id": 25,
        title: "Tronzen-2",
        description: "tronzen2",
        imgSrc:  require('../Images/MLM/tronzen2.webp'),
        altText: "tronzen2",
      },
      {
        "screen-shot-id": 26,
        title: "Tronzen-3",
        description: "tronzen3",
        imgSrc:  require('../Images/MLM/tronzen3.webp'),
        altText: "tronzen3",
      },
      
    ],
  },
  ///////////////// CEX ////////////////
  {
    id: 3,
    title: "CEX",
    class: "cex_bg",
    benefit1: "Advanced Order Book System", benefit2: "Highly Secured Platform", benefit3: "Crypto Wallet", benefit4: "100% Customizable",
    content: "Content for Card 1",
    segment:"Experience the seamless world of centralized trading with our showcased screenshots of centralized exchange (CEX) development projects. Witness user-friendly interfaces, robust security measures, and liquidity that support efficient digital asset trading",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Adverb",
        description: "Screenshot 1",
        imgSrc:  require('../Images/cefi/adverb.webp'),
        altText: "adverb",
      },
      {
        "screen-shot-id": 2,
        title: "Farmcex",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/farmcex.webp'),
        altText: "Farmcex",
      },
      {
        "screen-shot-id": 3,
        title: "Justbit",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/justbit.webp'),
        altText: "justbit",
      },
      {
        "screen-shot-id": 4,
        title: "Taikonz",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/taiko.webp'),
        altText: "taiko",
      },
      {
        "screen-shot-id": 5,
        title: "Bitbuyerx",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/bitbuyerx.png'),
        altText: "bitbuyerx",
      },
    ],
  },
  ///////////////// DEX ////////////////
  { 
    id: 4,
    title: "DEX",
    class: "dex_bg",
    benefit1: "High Speed", benefit2: "Multi layer Security", benefit3: "Multi currency support", benefit4: "Bot Trading",
    content: "Content for Card 1",
    segment:"Dive into the world of decentralized trading with our showcased screenshots from successful decentralized exchange (DEX) developments. Explore secure, transparent, and user-centric platforms that empower individuals to trade digital assets directly",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Yarloo",
        description: "Screenshot 1",
        imgSrc:  require('../Images/Defi/1.webp'),
        altText: "Yarloo",
      },
      {
        "screen-shot-id": 2,
        title: "Yarloo-1",
        description: "Screenshot 2",
        imgSrc:  require('../Images/Defi/2.webp'),
        altText: "Yarloo",
      },
      {
        "screen-shot-id": 3,
        title: "Keedx",
        description: "Screenshot 2",
        imgSrc:  require('../Images/Defi/keedx.png'),
        altText: "Keedx",
      },
    ],
  },
  ///////////////// TOKEN ////////////////
  {
    id: 5,
    title: "TOKEN",
    class: "token_bg",
    benefit1: "Safe and Secure", benefit2: "On-time delivery", benefit3: "Smart contract solution", benefit4: "White paper development",
    content: "Content for Card 1",
    segment:"Discover the creativity and versatility of token development through our showcased screenshots. Witness the creation of unique tokens that power decentralized ecosystems, enabling utility, rewards, and innovative functionalities",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Screenshot 1",
        description: "Screenshot 1",
        imgSrc:  require('../Images/screenshot.webp'),
        altText: "Screenshot 1",
      },
      {
        "screen-shot-id": 2,
        title: "Screenshot 2",
        description: "Screenshot 2",
        imgSrc:  require('../Images/screenshot.webp'),
        altText: "Screenshot 2",
      },
    ],
  },
  ///////////////// LANCHPAD ////////////////
  {
    id: 6,
    title: "LANCHPAD",
    class: "lanchpad_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"Unlock the potential of token sales and fundraising. Browse through our collection of screenshots showcasing seamless launchpad development, providing secure and efficient platforms for successful project launches and token distribution",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Adverb",
        description: "Screenshot 1",
        imgSrc:  require('../Images/cefi/adverb.webp'),
        altText: "adverb",
      },
      {
        "screen-shot-id": 2,
        title: "Justbit",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/justbit.webp'),
        altText: "justbit",
      },
      {
        "screen-shot-id": 3,
        title: "Taikonz",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/taiko.webp'),
        altText: "taiko",
      },
    ],
  },
  ///////////////// NFT MARKETPLACE ////////////////
  {
    id: 7,
    title: "NFT  MARKETPLACE",
    class: "nft_bg",
    benefit1: "Storefront", benefit2: "Trading module", benefit3: "Wallet integration", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"Explore a curated selection of captivating screenshots from our previous NFT marketplace projects. Witness the vibrant world of digital art, collectibles, and unique tokenized assets. Immerse yourself in the visually stunning and innovative realm of NFTs, where creativity meets blockchain technology, revolutionizing the way we perceive and interact with digital assets",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Fantically",
        description: "Screenshot 2",
        imgSrc:  require('../Images/NFT/Fantically.webp'),
        altText: "Fantically",
      },
      {
        "screen-shot-id": 2,
        title: "Loud",
        description: "Screenshot 2",
        imgSrc:  require('../Images/NFT/Loud.webp'),
        altText: "Loud",
      }, {
        "screen-shot-id": 3,
        title: "Musset",
        description: "Screenshot 2",
        imgSrc:  require('../Images/NFT/musset.webp'),
        altText: "Musset",
      }, {
        "screen-shot-id": 4,
        title: "Xverse",
        description: "Screenshot 2",
        imgSrc:  require('../Images/NFT/xverse.webp'),
        altText: "Xverse",
      },{
        "screen-shot-id": 5,
        title: "NFT",
        description: "Screenshot 2",
        imgSrc:  require('../Images/NFT/nft.png'),
        altText: "NFT",
      },
    ],
  },
  ///////////////// STACKING FARMING ////////////////
  {
    id: 8,
    title: "STAKING FARMING",
    class: "staking_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"Experience the power of passive income and stake with confidence. Explore our showcased screenshots from successful staking platform developments, enabling users to participate and earn rewards while contributing to blockchain networks",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Adverb",
        description: "Screenshot 1",
        imgSrc:  require('../Images/cefi/adverb.webp'),
        altText: "adverb",
      },
      {
        "screen-shot-id": 2,
        title: "Farmcex",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/farmcex.webp'),
        altText: "Farmcex",
      },
      {
        "screen-shot-id": 3,
        title: "Justbit",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/justbit.webp'),
        altText: "justbit",
      },
      {
        "screen-shot-id": 4,
        title: "Taikonz",
        description: "Screenshot 2",
        imgSrc:  require('../Images/cefi/taiko.webp'),
        altText: "taiko",
      },
    ],
  },

   ///////////////// Gaming ////////////////
   {
    id: 9,
    title: "Gaming Development",
    class: "gaming_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"Immerse Yourself in the World of Gaming Excellence!  At Block Intelligence, we take pride in creating successful gaming projects that captivate players and elevate gaming experiences. Explore our achievements in the gaming realm where creativity meets innovation. Join us on this gaming adventure!",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Lott",
        description: "Screenshot 1",
        imgSrc:  require('../Images/game/lott.webp'),
        altText: "Lott",
      },
      {
        "screen-shot-id": 2,
        title: "LoveLace",
        description: "Screenshot 2",
        imgSrc:  require('../Images/game/lott3Love.webp'),
        altText: "LoveLace",
      },
      {
        "screen-shot-id": 3,
        title: "Zoomfi",
        description: "Screenshot 2",
        imgSrc:  require('../Images/game/zoomfi (1).webp'),
        altText: "Zoomfi",
      },
      {
        "screen-shot-id": 4,
        title: "Zoomfi-2",
        description: "Screenshot 2",
        imgSrc:  require('../Images/game/zoomfi (2).webp'),
        altText: "Zoomfi",
      },
      {
        "screen-shot-id": 5,
        title: "Zoomf-3",
        description: "Screenshot 2",
        imgSrc:  require('../Images/game/zoomfi (3).webp'),
        altText: "Zoomfi-3",
      },
    ],
  },

  /////////////////// Bot //////////////////////

  {
    id: 10,
    title: "Bot Development",
    class: "bot_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"It is the process of creating software that can exploit price differences across various cryptocurrency exchanges. With a crypto arbitrage bot, you can automate your trading and generate profits with minimal effort.",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Arbitrage Bot",
        description: "Screenshot 1",
        imgSrc:  require('../Images/bot/arbitrage.png'),
        altText: "Arbitrage Bot",
      },
      {
        "screen-shot-id": 2,
        title: "Market Making Bot",
        description: "Screenshot 2",
        imgSrc:  require('../Images/bot/marketmaking.png'),
        altText: "Market Making Bot",
      },
    ],
  },


  /////////////////////Crypto Payment////////////////////

  {
    id: 11,
    title: "Crypto Payment",
    class: "payment_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"It is the process of creating a platform that enables businesses to accept payments in various digital assets, such as Bitcoin, Ethereum, and stablecoins. With a crypto payment gateway, you can offer your customers faster, cheaper, and more secure transactions across the globe.",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Crypto Payment Gateway",
        description: "Screenshot 1",
        imgSrc:  require('../Images/bot/gateway.png'),
        altText: "Crypto Payment Gateway",
      },
     
    ],
  },

  /////////////////BlockChain End ////////////////
  /////////////////AI & ML End ////////////////

  {
    id: 21,
    title: "Graphics",
    class: "graphics_bg",
    benefit1: "Customized Solutions", benefit2: "Creative designs", benefit3: "Improved Marketing Collateral", benefit4: "Consistency and Brand Cohesion",
    content: "Content for Card 1",
    segment:"Explore a glimpse of our visually captivating designs through this showcase of stunning screenshots. From eye-catching illustrations to sleek UI designs, these graphics demonstrate our commitment to delivering aesthetically pleasing and impactful visual solutions. Immerse yourself in our world of creativity and discover the power of captivating visuals",
    screenshots: [
    ],
  },

  {
    id: 22,
    title: "Digital arts",
    class: "arts_bg",
    benefit1: "Customized Solutions", benefit2: "Creative designs", benefit3: "Improved Marketing Collateral", benefit4: "Consistency and Brand Cohesion",
    content: "Content for Card 1",
    segment:"Explore a glimpse of our visually captivating designs through this showcase of stunning screenshots. From eye-catching illustrations to sleek UI designs, these graphics demonstrate our commitment to delivering aesthetically pleasing and impactful visual solutions. Immerse yourself in our world of creativity and discover the power of captivating visuals",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Blockpad",
        description: "blockpad",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-ezgif-com-gif-maker.gif'),
        altText: "blockpad",
      },
      {
        "screen-shot-id": 2,
        title: "Blockpad-1",
        description: "blockpad1",
        imgSrc:  require('../Images/services/graphics/2.webp'),
        altText: "blockpad1",
      },
      {
        "screen-shot-id": 3,
        title: "Blockpad-2",
        description: "blockpad2",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-01.webp'),
        altText: "blockpad2",
      },
      {
        "screen-shot-id": 4,
        title: "Blockpad-3",
        description: "blockpad3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-02.webp'),
        altText: "blockpad3",
      },
      {
        "screen-shot-id": 5,
        title: "Mysticwarriors",
        description: "mysticwarriors",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-0203.webp'),
        altText: "mysticwarriors",
      },
      {
        "screen-shot-id": 6,
        title: "Mysticwarriors-1",
        description: "mysticwarriors1",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-04.webp'),
        altText: "mysticwarriors1",
      },
      {
        "screen-shot-id": 7,
        title: "Mysticwarriors-2",
        description: "mysticwarriors2",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-07.webp'),
        altText: "mysticwarriors2",
      },
      {
        "screen-shot-id": 8,
        title: "Mysticwarriors-3",
        description: "mysticwarriors3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-08.webp'),
        altText: "mysticwarriors3",
      },
      {
        "screen-shot-id": 9,
        title: "Yolocab",
        description: "yolocab",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-12.webp'),
        altText: "yolocab",
      },
      {
        "screen-shot-id": 10,
        title: "Yolocab-1",
        description: "yolocab1",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-24.webp'),
        altText: "yolocab1",
      },
      {
        "screen-shot-id": 11,
        title: "Yolocab-2",
        description: "yolocab2",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-25.webp'),
        altText: "yolocab2",
      },
      {
        "screen-shot-id": 12,
        title: "Yolocab-3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-26.webp'),
        altText: "yolocab3",
      },
      {
        "screen-shot-id": 13,
        title: "Yolocab-4",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-bot001.webp'),
        altText: "yolocab3",
      },
      {
        "screen-shot-id": 14,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-bot002.webp'),
        altText: "yolocab3",
      }, {
        "screen-shot-id": 15,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-bot004.webp'),
        altText: "yolocab3",
      }, {
        "screen-shot-id": 16,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-elfhuhter.webp'),
        altText: "yolocab3",
      }, {
        "screen-shot-id": 17,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-elfhuhtersketch.webp'),
        altText: "yolocab3",
      }, {
        "screen-shot-id": 18,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/1.webp'),
        altText: "yolocab3",
      }, {
        "screen-shot-id": 19,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-image.webp'),
        altText: "yolocab3",
      },
      {
        "screen-shot-id": 20,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-jhar-dakan02.webp'),
        altText: "yolocab3",
      },{
        "screen-shot-id": 21,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-robots.webp'),
        altText: "yolocab3",
      },{
        "screen-shot-id": 22,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-silverhand.webp'),
        altText: "yolocab3",
      },{
        "screen-shot-id": 23,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-siennafinal02.webp'),
        altText: "yolocab3",
      },{
        "screen-shot-id": 24,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-thumbs-bardcorlor.webp'),
        altText: "yolocab3",
      },{
        "screen-shot-id": 25,
        title: "yolocab3",
        description: "yolocab3",
        imgSrc:  require('../Images/services/graphics/gabriel-nascimento-yelenafinal02.webp'),
        altText: "yolocab3",
      },
    ],
  },

  {
    id: 23,
    title: "Logo",
    class: "logo_bg right",
    benefit1: "Customized Solutions", benefit2: "Creative designs", benefit3: "Improved Marketing Collateral", benefit4: "Consistency and Brand Cohesion",
    content: "Content for Card 1",
    segment:"Explore a glimpse of our visually captivating designs through this showcase of stunning screenshots. From eye-catching illustrations to sleek UI designs, these graphics demonstrate our commitment to delivering aesthetically pleasing and impactful visual solutions. Immerse yourself in our world of creativity and discover the power of captivating visuals",
    screenshots: [
      
    {
      "screen-shot-id": 26,
      title: "yolocab3",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i1.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 27,
      title: "yolocab3",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i2.webp'),
      altText: "yolocab3",
    },
    {
      "screen-shot-id": 28,
      title: "Dredshep",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i3.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 29,
      title: "Memeunity",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i4.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 30,
      title: "Stepaid",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i5.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 31,
      title: "Venom",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i6.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 32,
      title: "Trhone Wars",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i8.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 33,
      title: "Kikiagua",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i9.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 34,
      title: "Yarloo",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i11.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 35,
      title: "yolocab3",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i12.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 36,
      title: "MU Valut",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i13.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 37,
      title: "Onlyfandom",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i14.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 38,
      title: "yolocab3",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i15.webp'),
      altText: "yolocab3",
    },{
      "screen-shot-id": 39,
      title: "Robotly",
      description: "yolocab3",
      imgSrc:  require('../Images/services/graphics/i16.webp'),
      altText: "yolocab3",
    },
    ],
  },
  /////////////////Media & Graphics End ////////////////
  

  ///////////////// WEB DEVELOPMENT PROJECTS////////////////
  {
    id: 31,
    class: "web_bg ",
    title: "Web Development Projects",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Blockpad",
        description: "blockpad",
        imgSrc:  require('../Images/WebDev/blockpad.webp'),
        altText: "blockpad",
      },
      {
        "screen-shot-id": 2,
        title: "Blockpad-1",
        description: "blockpad1",
        imgSrc:  require('../Images/WebDev/blockpad1.webp'),
        altText: "blockpad1",
      },
      {
        "screen-shot-id": 3,
        title: "Blockpad-2",
        description: "blockpad2",
        imgSrc:  require('../Images/WebDev/blockpad2.webp'),
        altText: "blockpad2",
      },
      {
        "screen-shot-id": 4,
        title: "Blockpad-3",
        description: "blockpad3",
        imgSrc:  require('../Images/WebDev/blockpad3.webp'),
        altText: "blockpad3",
      },
      {
        "screen-shot-id": 5,
        title: "Mysticwarriors",
        description: "mysticwarriors",
        imgSrc:  require('../Images/WebDev/mysticwarriors.webp'),
        altText: "mysticwarriors",
      },
      {
        "screen-shot-id": 6,
        title: "Mysticwarriors-1",
        description: "mysticwarriors1",
        imgSrc:  require('../Images/WebDev/mysticwarriors1.webp'),
        altText: "mysticwarriors1",
      },
      {
        "screen-shot-id": 7,
        title: "Mysticwarriors-2",
        description: "mysticwarriors2",
        imgSrc:  require('../Images/WebDev/mysticwarriors2.webp'),
        altText: "mysticwarriors2",
      },
      {
        "screen-shot-id": 8,
        title: "Mysticwarriors-3",
        description: "mysticwarriors3",
        imgSrc:  require('../Images/WebDev/mysticwarriors3.webp'),
        altText: "mysticwarriors3",
      },
      {
        "screen-shot-id": 9,
        title: "Yolocab",
        description: "yolocab",
        imgSrc:  require('../Images/WebDev/yolocab.webp'),
        altText: "yolocab",
      },
      {
        "screen-shot-id": 10,
        title: "Yolocab-1",
        description: "yolocab1",
        imgSrc:  require('../Images/WebDev/yolocab1.webp'),
        altText: "yolocab1",
      },
      {
        "screen-shot-id": 11,
        title: "Yolocab-2",
        description: "yolocab2",
        imgSrc:  require('../Images/WebDev/yolocab2.webp'),
        altText: "yolocab2",
      },
      {
        "screen-shot-id": 12,
        title: "Yolocab-3",
        description: "yolocab3",
        imgSrc:  require('../Images/WebDev/yolocab3.webp'),
        altText: "yolocab3",
      },
     
    ],
  },

  {
    id: 32,
    title: "App Development Projects",
    class: "app_bg",
    benefit1: "Better UI", benefit2: "Highly flexible", benefit3: "Faster access", benefit4: "Secure",
    content: "Content for Card 1",
    segment:"Witness the Success of Our Mobile App Projects! At Block Intelligence, we pride ourselves on delivering innovative mobile applications that drive business growth and user engagement. Check out our successful projects, where innovation meets excellence. Join us in shaping the future of mobile technology!",
    screenshots: [
      {
        "screen-shot-id": 1,
        title: "Farmcex",
        description: "blockpad",
        imgSrc:  require('../Images/mobile/farmcex.webp'),
        altText: "blockpad",
      },
      {
        "screen-shot-id": 2,
        title: "Papennyx",
        description: "blockpad1",
        imgSrc:  require('../Images/mobile/papennyx.webp'),
        altText: "blockpad1",
      },
      {
        "screen-shot-id": 3,
        title: "Taikonz",
        description: "blockpad2",
        imgSrc:  require('../Images/mobile/taikonz.webp'),
        altText: "blockpad2",
      },

    ],
  },
 
];
  export default cardDetails;
  