import React, { useState, useEffect } from "react";
import "../Pages/Style.css";
import logo from "../Images/new_logo.webp";
import newLogo from "../Images/logo.webp";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Change the value (e.g., 100) to the desired scroll position where you want to update the header
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`navbar navbar-header navbar-expand-lg ${isScrolled ? "scrolled" : ""}`}>
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img
              src={isScrolled ? newLogo : logo}
              height="32"
              alt="CoolBrand"
            />
          </a>
          <button
            class="navbar-toggler navbar-light"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <div className="navbar-nav ml-auto">
              <a href="/" className="nav-item nav-link nav-a">
                Home
              </a>

              <a href="/client" className="nav-item nav-link nav-a">
                Clients
              </a>

              <a href="https://wa.me/7738479381" target="_blank" className="nav-item nav-link nav-a">
                <img

                  src={require("../Images/whatsapp.webp")}
                  className="icon"
                  alt="Whatsapp"
                />

              </a>



              <a href="https://join.skype.com/invite/tm3uaTeSeHpF" target="_blank" className="nav-item nav-link nav-a">
                <img
                  src={require("../Images/skype.webp")}
                  className="icon"
                  alt="skype"
                />
              </a>

              <a href="https://t.me/jetsoanalin" target="_blank" className="nav-item nav-link nav-a">
                <img
                  src={require("../Images/telegram.webp")}
                  className="icon"
                  alt="telegram"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
