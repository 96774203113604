import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Pages/Home'
import Faq from './Pages/Faq'
import E404Page from './Pages/404page'
import Details from './Pages/DetailsPage'
import Video from './Pages/Video-list'
import Clients from './Pages/Clients'

export default function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={< Home/>} />
      <Route path="/client" element={< Clients/>} />
      <Route path="/faq" element={< Faq/>} />
      <Route path="/404Page" element={< E404Page />} /> 
      <Route path="/details/:id" element={<Details />} />
      <Route path="/Video/:id" element={< Video />} />
      </Routes>
      </BrowserRouter>
    </div>
  )
}
