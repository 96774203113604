import React from "react";
import "../Pages/Style.css";

export default function Footer() {
  return (
    <>
      <section className="copyrights">
        <div>
        <hr />
        <h4>© Copyright 2023 Block Intelligence | All Rights Reserved </h4>
        </div>
      </section>
    </>
  );
}
