import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DetailsPage from "./DetailsPage.js";
import "./Style.css";

export default function Home() {
  const navigate = useNavigate();

  const BlockData = [
    {
      id: 1,
      title: "Initial Coin Offering",
      description: "Launch your project with confidence and attract investors through our comprehensive ICO solutions, ensuring a successful token sale",
      class: "bl-bg-ser1",
    },
    {
      id: 2,
      title: "Multi-Level Marketing",
      description: " Drive growth and engagement with our MLM solutions, leveraging blockchain technology to revolutionize your marketing strategies",
      class: "bl-bg-ser2",
    },
    {
      id: 3,
      title: "Centralized Exchange",
      description: "Build a robust and secure centralized exchange platform tailored to your requirements, enabling seamless trading experiences for your users",
      class: "bl-bg-ser3",
    },
    {
      id: 4,
      title: "Decentralized Exchange",
      description: " Embrace the future of trading with our decentralized exchange development services, empowering users with control over their assets and enhanced security",
      class: "bl-bg-ser4",
    },
    {
      id: 5,
      title: "TOKEN",
      description: "Create your own customized tokens to fuel your blockchain project, providing utility, value, and unique functionalities to your ecosystem",
      class: "bl-bg-ser5",
    },
    {
      id: 6,
      title: "LANCHPAD",
      description: "Launch your tokens and projects successfully with our launchpad development services, facilitating secure and efficient token sales and fundraising",
      class: "bl-bg-ser6",
    },
    {
      id: 7,
      title: "NFT MARKETPLACE",
      description: "Capitalize on the NFT trend with our NFT marketplace development services, creating a platform to showcase, trade, and monetize digital assets",
      class: "bl-bg-ser7",
    },
    {
      id: 8,
      title: "STAKING FARMING",
      description: "Enable users to participate in staking and earn rewards by leveraging our staking platform development, empowering secure and efficient token staking",
      class: "bl-bg-ser8",
    },
    {
      id: 9,
      title: "Gaming Development",
      description: "Enable users to participate in staking and earn rewards by leveraging our staking platform development, empowering secure and efficient token staking",
      class: "bl-bg-ser9",
    },
    {
      id: 10,
      title: "Bot Development",
      description: "It is the process of creating software that can exploit price differences across various cryptocurrency exchanges. With a crypto arbitrage bot, you can automate your trading and generate profits with minimal effort.",
      class: "bl-bg-ser10",
    },
    {
      id: 11,
      title: "Crypto Payment",
      description: "It is the process of creating a platform that enables businesses to accept payments in various digital assets, such as Bitcoin, Ethereum, and stablecoins. With a crypto payment gateway, you can offer your customers faster, cheaper, and more secure transactions across the globe.",
      class: "bl-bg-ser11",
    },
    
  ];

  // const AIData = [
  //   {
  //     id: 11,
  //     title: "Binance Clone Script",
  //     description:
  //       "Our Binance clone script replicates the powerful features of the renowned Binance exchange. Enjoy a robust trading engine, multi-cryptocurrency support, advanced order types, and a user-friendly interface, just like the original platform.",
  //   },
  //   {
  //     id: 12,
  //     title: "Vera Clone Script",
  //     description:
  //       "Our Vera clone script replicates the powerful features of the renowned Vera platform. Experience advanced functionality, seamless user experience, and cutting-edge technology, just like the original platform.",
  //   },
  // ];

  const MediaData = [
    {
      id: 21,
      title: "Graphics",
      description: "Elevate your brand with visually stunning designs that capture attention and convey your message effectively, crafted by our skilled team of graphic design experts",
      class: "graphic-bg-ser1",
    },

    {
      id: 22,
      title: "Digital Art",
      description: "Unlock the realm of digital creativity with our digital art services, where our talented artists bring imagination to life, delivering captivating visuals and immersive experiences",
      class: "graphic-bg-ser2",
    },
    {
      id: 23,
      title: "Logo",
      description: "Make a lasting impression with a professionally designed logo that reflects your brand identity, stands out from the crowd, and leaves a memorable mark on your audience",
      class: "graphic-bg-ser3",
    },
  ];

  const WebData = [
    {
      id: 31,
      title: "WEB DEV PROJECTS",
      class: "web-bg-ser1",
      description: "Transform your digital presence with our expert web development services. From responsive designs to seamless functionality, we craft engaging websites that enhance user experience and drive business growth",
    },
    {
      id: 32,
      title: "APP DEV PROJECTS",
      class: "web-bg-ser2",
      description: "Transform your digital presence with our expert web development services. From responsive designs to seamless functionality, we craft engaging websites that enhance user experience and drive business growth",
    },
  ];

  return (
    <>
      <Header />
      <section className="hero">
        <div className="hero-bg">
          <div className="container">
            <div className="hero-content">
              <h1 >BLOCK INTELLIGENCE<br/> <span> - Blockchain and AI Development Company
              </span></h1>
              <p className='animate-gradient' >The future of tech innovations starts here. Empowering businesses with advanced blockchain technology and AI innovation, we drive digital transformation and shape the future of industries</p>
              <a href="#session2">Explore Now</a>
            </div>
          </div>
        </div>
      </section>
      <section className="sub-category" id="session2">
        <div className="hero-bg">
          <div className="container">
            <div className="tab-section">
              <ul
                class="nav nav-pills mb-3 tab-row"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active sub-tab"
                    id="pills-blockchain-tab"
                    data-toggle="pill"
                    href="#pills-blockchain"
                    role="tab"
                    aria-controls="pills-blockchain"
                    aria-selected="true"
                  >
                    Blockchain
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a
                    class="nav-link sub-tab"
                    id="pills-ai-tab"
                    data-toggle="pill"
                    href="#pills-ai"
                    role="tab"
                    aria-controls="pills-ai"
                    aria-selected="false"
                  >
                    AI/ML
                  </a>
                </li> */}
                <li class="nav-item">
                  <a
                    class="nav-link sub-tab"
                    id="pills-graphics-tab"
                    data-toggle="pill"
                    href="#pills-graphics"
                    role="tab"
                    aria-controls="pills-graphics"
                    aria-selected="false"
                  >
                    Media & Graphics
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link sub-tab"
                    id="pills-web-tab"
                    data-toggle="pill"
                    href="#pills-web"
                    role="tab"
                    aria-controls="pills-web"
                    aria-selected="false"
                  >
                    App/Web Development
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-blockchain"
                  role="tabpanel"
                  aria-labelledby="pills-blockchain-tab"
                >
                  <div className="sub-category-card-row">
                    <div className="row">
                      {BlockData.map((card) => (
                        <div className="col-lg-4" key={card.id}>
                          <div className={`sub-category-card ${card.class}`}>
                            <div className="sub-category-card-content">
                              <p className="sub-category-card-content-title">
                                {card.title}
                              </p>
                              <p className="sub-category-card-content-desc">
                                {card.description}
                              </p>
                              <button
                                className="btn btn-light"
                                onClick={() => navigate(`/details/${card.id}`)}
                              >
                                Know More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div
                  class="tab-pane fade"
                  id="pills-ai"
                  role="tabpanel"
                  aria-labelledby="pills-ai-tab"
                >
                  <div className="sub-category-card-row">
                    <div className="row">
                      {AIData.map((card) => (
                        <div className="col-lg-4" key={card.id}>
                          <div className={`sub-category-card ${card.class}`}>
                            <div className="sub-category-card-content">
                              <p className="sub-category-card-content-title">
                                {card.title}
                              </p>
                              <p className="sub-category-card-content-desc">
                                {card.description}
                              </p>
                              <button
                                className="btn btn-light"
                                onClick={() => navigate(`/details/${card.id}`)}
                              >
                                Know More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
                <div
                  class="tab-pane fade"
                  id="pills-graphics"
                  role="tabpanel"
                  aria-labelledby="pills-grahics-tab"
                >
                  <div className="sub-category-card-row">
                    <div className="row">
                      {MediaData.map((card) => (
                        <div className="col-lg-4" key={card.id}>
                          <div className={`sub-category-card ${card.class}`}>
                            <div className="sub-category-card-content">
                              <p className="sub-category-card-content-title">
                                {card.title}
                              </p>
                              <p className="sub-category-card-content-desc">
                                {card.description}
                              </p>
                              <button
                                className="btn btn-light"
                                onClick={() => navigate(`/Video/${card.id}`)}
                              >
                                Know More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-web"
                  role="tabpanel"
                  aria-labelledby="pills-web-tab"
                >
                  <div className="sub-category-card-row">
                    <div className="row">
                      {WebData.map((card) => (
                        <div className="col-lg-4" key={card.id}>
                          <div className={`sub-category-card ${card.class}`}>
                            <div className="sub-category-card-content">
                              <p className="sub-category-card-content-title">
                                {card.title}
                              </p>
                              <p className="sub-category-card-content-desc">
                                {card.description}
                              </p>
                              <button
                                className="btn btn-light"
                                onClick={() => navigate(`/details/${card.id}`)}
                              >
                                Know More
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
